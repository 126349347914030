import React from "react"
import Navbar from "../../components/navbar"
import Layout from "../../components/layout"
import Head from "../../components/head"
import Button from "../../components/button"


export default function GetPricing () {

    return (
      <Layout>
        <Head title="Thank you" description="From basic dropshipping & automation to Advanced and custom reporting, we’ve got you covered. Find the right package for your business"/>
         
        <div className='d-flex justify-content-center align-items-center' style={{height: '100vh', width: '100vw', background: '#3b77ce'}}>
            <div className="text-center my-5 py-5 text-white">
                <p className="feature-highlight__title mb-5">Thank you for contacting us, we will get back to you as soon as possible.</p>
                <Button style={{ shape: "btn-round btn-round__border", text: "Go back ", href: "/enterprise"}}/>
            </div>
        </div>
        <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2137788&vkey=1b3269be6e3c436bd99a8c4fa5e9d39f" />
      </Layout>
    )
}
